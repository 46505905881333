import NextPrev from "shared/NextPrev/NextPrev";
import React, { HTMLAttributes, ReactNode } from "react";

export interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  fontClass?: string;
  desc?: ReactNode;
  hasNextPrev?: boolean;
  isCenter?: boolean;
  onlineOrderSettings?: { customTextOne: string};
}

const Heading: React.FC<HeadingProps> = ({
  children,
  desc,
 onlineOrderSettings,
  className = "text-sm mb-10 md:mb-6 ",
  isCenter = false,
  hasNextPrev = false,
  ...args
}) => {
  return (
    <div
      className={`nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between ${className}`}
    >
      <div
        className={
          isCenter ? "text-center w-full max-w-2xl mx-auto mb-4" : "max-w-2xl"
        }
      >
        <h4 className={`text-3xl md:text-3xl font-semibold`} {...args}>
          {children || `Section Heading`}
        </h4>
        {onlineOrderSettings && (
          <span className="mt-1 md:mt-2 font-normal block text-base sm:text-lg text-neutral-500 dark:text-neutral-400">
            {desc || onlineOrderSettings?.customTextOne}
          </span>
        )}
      </div>
      {hasNextPrev && !isCenter && (
        <div className="mt-4 flex justify-end sm:ml-2 sm:mt-0 flex-shrink-0">
          <NextPrev onClickNext={() => {}} onClickPrev={() => {}} />
        </div>
      )}
    </div>
  );
};

export default Heading;
