
import { Controller,useFormContext  } from 'react-hook-form'; // Assuming you're using react-hook-form
import Select from "react-select";// Replace with actual imports from your component library
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import { State } from "country-state-city";
import React, { useMemo, useState,useEffect, Dispatch, SetStateAction } from "react";
interface BillingAddressFormProps {
  control: any; // Replace with the type for your form control
    errors: any;
  setValue:any,
    clearErrors: any,
    namespace:any,
    
    // setFormValue:Dispatch<SetStateAction<any>>
}
  
const BillingAddressForm: React.FC<BillingAddressFormProps> = ({ control, errors, setValue, clearErrors, namespace }) => {
    const formatOptionLabel = ({ label, value }: any, { context }: any) => {
    return context === "value" ? value : label;
    };
    const stateOption = useMemo(() => {
    return State.getAllStates()
      .filter((code: any) => code.countryCode === "US")
      .map((state: any) => ({
        label: state.name,
        value: state.isoCode,
      }));
  }, []);

  
  const[formValue , setFormValue]=useState({})
  const hanldeChange=(e:any)=>{
   const {name , value}=e?.target
  setFormValue((prev:any)=>({
    ...prev,
    [name]:value
  }))
  localStorage.setItem('BillingAddress',JSON.stringify({...formValue,[name]:value}))
  }
    //const { clearErrors,setValue } = useFormContext();
    return (
      <div className={`mt-6 mb-4 space-y-3 sm:space-y-5`}>
        <h1 className="text-1xl font-bold">Billing Address</h1>
        <div className="max-w-lg">
          <Label className="text-sm"> Address 1</Label>
          <Controller
            name={`${namespace}.address1`}
            control={control}
            rules={{
              required: "Address is Required",
            }}
            render={({ field }) => (
              <Input
                {...field}
                className="mt-1.5"
                onInput={(e: any) =>hanldeChange(e)}
              />
            )}
          />
          {errors[`${namespace}`]?.address1 && (
            <span className="text-red-500">
              {errors[`${namespace}`]?.address1.message}
            </span>
          )}
        </div>
        <div className="max-w-lg">
          <Label className="text-sm">Address 2</Label>
          <Controller
            name={`${namespace}.address2`}
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                className="mt-1.5"
                onInput={(e: any) =>hanldeChange(e)}
              />
            )}
          />
        </div>
        <div className="flex flex-col sm:flex-row sm:space-y-0 sm:space-x-3">
          <div className="flex-1">
            <Label className="text-sm">City</Label>
            <Controller
              name={`${namespace}.city`}
              control={control}
              rules={{
                required: "City is Required",
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  className="mt-1.5"
                  onInput={(e: any) => hanldeChange(e)}
                />
              )}
            />
            {errors[`${namespace}`]?.city && (
              <span className="text-red-500">
                {errors[`${namespace}`]?.city.message}
              </span>
            )}
          </div>
          <div className="flex-1">
            <Label className="text-sm">State</Label>
            <Controller
              name={`${namespace}.state`}
              control={control}
              rules={{
                required: "State is Required",
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Select
                  //   inputRef={ref}
                  className="mt-1.5 input-focus-disable"
                  options={stateOption}
                  isSearchable
                  value={stateOption.find((option) => option.value === value)}
                  onChange={(selectedOption,e) => {
                    onChange(selectedOption ? selectedOption.value : "");
                    clearErrors("state");
                    hanldeChange({ target: { name: "payment.state", value: selectedOption?.value } })
                  }}
                  onBlur={onBlur}
                  formatOptionLabel={formatOptionLabel}
                />
              )}
            />
            {errors[`${namespace}`]?.state && (
              <span className="text-red-500">
                {errors[`${namespace}`]?.state.message}
              </span>
            )}
          </div>
        </div>
        <div className="flex flex-col sm:flex-row sm:space-y-0 sm:space-x-3">
          <div className="flex-1">
            <Label className="text-sm">Country</Label>
            <Controller
              name={`${namespace}.country`}
              control={control}
              rules={{
                required: "Country is Required",
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  className="mt-1.5"
                  onInput={(e: any) => hanldeChange(e)}
                />
              )}
            />
            {errors[`${namespace}`]?.country && (
              <span className="text-red-500">
                {errors[`${namespace}`]?.country.message}
              </span>
            )}
          </div>
          <div className="flex-1">
            <Label className="text-sm">Zipcode</Label>
            <Controller
              name={`${namespace}.zip`}
              control={control}
              rules={{
                required: "Zipcode is Required",
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  className="mt-1.5"
                  onInput={(e: any) => {
                    e.target.value = e.target.value
                      .replace(/\D/g, "")
                      .slice(0, 6);
                      hanldeChange(e)
                  }}
                />
              )}
            />
            {errors[`${namespace}`]?.zip && (
              <span className="text-red-500">
                {errors[`${namespace}`]?.zip.message}
              </span>
            )}
          </div>
        </div>
      </div>
    );
}
export default BillingAddressForm;