import React, { FC, useEffect, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { GoogleLogin } from "@react-oauth/google";
import { LoginSocialFacebook } from "reactjs-social-login";
import { FacebookLoginButton } from "react-social-login-buttons";
import { postLoginData } from "services/HomeService";
import { AxiosResponse } from "axios";
import { Controller, useForm } from "react-hook-form";
export interface PageLoginProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];
interface LoginResponse {
  "AuthNet Profile Info ": any;
  "Customer Info ": any;
}
const PageLogin = ({ className = "" }) => {
  const MERCHANT_STORAGE_KEY = "merchantname";
  const [errorMessage, setErrorMessage] = useState("");
  const merchantname = localStorage.getItem(MERCHANT_STORAGE_KEY);
  const navigate = useNavigate();
  const handleGoogleLoginSuccess = (credentialResponse: any) => {
    // Here, you can handle the response as needed, e.g., store tokens in localStorage
    console.log(credentialResponse);

    // Redirect the user to /:merchantname
    navigate(`/${merchantname}`);
  };
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const handleGoogleLoginError = () => {
    console.log("Login Failed");
  };
  const StyledGoogleLogin = () => {
    return (
      <div>
        <GoogleLogin
          onSuccess={handleGoogleLoginSuccess}
          onError={handleGoogleLoginError}
        />
      </div>
    );
  };

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const StyledFacebookLogin = () => {
    return (
      <div>
        <LoginSocialFacebook
          appId="1624961768273564"
          onResolve={(response) => {
            console.log(response);
            handleGoogleLoginSuccess(response);
          }}
          onReject={(error) => {
            console.log(error);
            // handleGoogleLoginError;
          }}
        >
          <FacebookLoginButton />
        </LoginSocialFacebook>
      </div>
    );
  };
  const MERCHANT_LOGIN = "userdata";
  const onSubmit: any = async (data: any) => {
    localStorage.removeItem("SignupData");
    localStorage.removeItem("userData");
    localStorage.removeItem("CustomerInfo");
    localStorage.removeItem("customerAdded");
    localStorage.removeItem("Info");
    localStorage.removeItem("userDataSignup");
    localStorage.removeItem("addedPayments");
    localStorage.removeItem("user");
    // e.preventDefault();
    try {
      const LoginData = {
        email: data.email,
        password: data.password,
        schemaName: merchantname,
      };
      // Call the postLoginData API with email and password
      const response: AxiosResponse<LoginResponse, any> = await postLoginData(
        LoginData
      );
      
      // Handle the response as needed
      console.log(response.data["Customer Info "], "responsedata");
      localStorage.setItem(
        "CustomerInfo",
        JSON.stringify(response.data["Customer Info "])
      );
      localStorage.setItem(
        "userData",
        JSON.stringify(response.data["AuthNet Profile Info "])
      );
      console.log(localStorage, "localstorage");
      const loginTime = new Date().getTime();
      localStorage.setItem("loginTime", loginTime.toString());
      // localStorage.removeItem("orderCards");
      navigate(`/${merchantname}`);
      // Redirect the user after successful login
      window.location.reload();
    } catch (error) {
      const ErrorResponse: any = error;
      setErrorMessage(ErrorResponse.response.data.message);
      console.error("Login Failed", error);
      // Handle login failure, e.g., show error message
    }
  };
  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Basil POS Online Ordering</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div
            className={`${errorMessage &&"bg-red-100 border text-red-700 px-4 py-3 rounded relative"}`}
            role="alert"
          >
            <span className="block sm:inline">{errorMessage}</span>
          </div>
          {/* <div className="flex flex-col items-center">
          
  <GoogleLogin
    onSuccess={handleGoogleLoginSuccess}
    onError={handleGoogleLoginError}
  />

 
            <LoginSocialFacebook 
              appId="1624961768273564"
              onResolve={(response) => {
                console.log(response);
                handleGoogleLoginSuccess(response);
              }}
              onReject={(error) => {
                console.log(error);
               // handleGoogleLoginError;
            }
            }
             
            >
              <FacebookLoginButton/>
            </LoginSocialFacebook>
            {/* {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))} */}

          {/* </div>  */}
          {/* <div className="flex flex-col items-center">
  <StyledGoogleLogin />
  <StyledFacebookLogin />
</div> */}
          {/* OR */}
          {/* <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div> */}
          {/* FORM */}
          <form
            className="grid grid-cols-1 gap-6"
            onSubmit={handleSubmit(onSubmit)}
          >
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email Address
              </span>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Email is required",
                }}
                render={({ field }) => (
                  <Input
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1"
                    {...field}
                    //  onChange={(e) => setEmail(e.target.value)}
                  />
                )}
              />
              {errors.email && (
                <span className="text-red-500">{errors.email.message}</span>
              )}
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
                {/* <Link
                  to={`/${merchantname}/forgot-pass`}
                  className="text-sm text-green-600"
                >
                  Forgot password?
                </Link> */}
              </span>
              <Controller
                name="password"
                control={control}
                rules={{ required: "password is required" }}
                render={({ field }) => (
                  <Input
                    type="password"
                    className="mt-1"
                    {...field}
                    // value={password}
                    // onChange={(e) => setPassword(e.target.value)}
                  />
                )}
              />
              {errors.password && (
                <span className="text-red-500">{errors.password.message}</span>
              )}
            </label>
            <ButtonPrimary type="submit">Continue</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link className="text-green-600" to={`/${merchantname}/signup`}>
              Create an account
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
