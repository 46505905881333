import React from "react";
import ReactDOM from "react-dom/client";
//
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
import "rc-slider/assets/index.css";
import { GoogleOAuthProvider } from '@react-oauth/google';
//
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
   <React.StrictMode>
    {/* <GoogleOAuthProvider clientId="9389737900-lbken8gqg04q25edca9v1fclvv2hpf8j.apps.googleusercontent.com"> */}
      <App />
      {/* </GoogleOAuthProvider> */}
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
